import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false

// 在每个路由跳转之前执行，用于设置页面的标题
router.beforeEach((to, from, next) => {
  // 如果路由中包含了title元数据，则设置页面的标题
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // 继续路由跳转
  next()
})

Vue.use(ElementUI)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
