import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index.vue'),
    meta: {
      title: '未来媒体实验室'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News.vue'),
    meta: {
      title: '新闻动态'
    }
  },
  {
    path: '/joinUs/teacher',
    name: 'JoinUs',
    component: () => import('@/views/JoinUs.vue'),
    meta: {
      title: '欢迎优秀的你加盟未来媒体实验室'
    }
  },
  {
    path: '/teams',
    name: 'Teams',
    component: () => import('@/views/Teams.vue'),
    meta: {
      title: '实验室团队'
    }
  },
  {
    path: '/article',
    name: 'Ariticle',
    component: () => import('@/views/Article.vue'),
    meta: {
      title: '新闻动态'
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/views/Project.vue'),
    meta: {
      title: '项目介绍'
    }
  },
  {
    path: '/joinUs',
    name: 'JoinUs',
    component: () => import('@/views/JoinUs.vue'),
    meta: {
      title: '加入我们'
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
