<template>
  <div id="app">
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    Header: () => import("@/components/Header.vue"),
    Footer: () => import("@/components/Footer.vue"),
  },
}
</script>

<style lang="less">
@import "./assets/app.less";
</style>